import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Introduction from "../../components/introduction"
import HeroAlt from "../../components/hero-alt"
import Spacer from "../../components/spacer"
import TitleAndText from "../../components/title-and-text"
import ImageAndText from "../../components/image-and-text"
import BulletList from "../../components/bullet-list"
import Bullet from "../../components/bullet"
import CenteredSlider from "../../components/centered-slider"
import CenteredSlide from "../../components/centered-slide"
import WorkGrid from "../../components/work-grid"
import ImageGridItemService from "../../components/image-grid-item-service"
import ContactForm from "../../components/contact-form"
import ImageGridItem from "../../components/image-grid-item"


class WebMaintenancePage extends Component {
  render() {
    const data = this.props.data

    return (
      <Layout background={ true } >
        <SEO title="Website Maintenance Bournemouth" />
        <HeroAlt 
          image="/maintenance.jpg"
          title="Website Maintenance"
          subtitle="Old Salt is your trusted partner to provide regular maintenance and perform essential updates to your website."
        />
        
        <Spacer space="4" />    

        <div className="row shrink-width align-center">

          <div className="column small-12 large-4">
            <h2 className="gm-bottom--1" style={{ lineHeight: 1 }}>Keeping you secure and online</h2>  
          </div>
          <div className="column small-12 large-8">
            <div className="row align-center">
              <div className="column small-12 large-10">
                <p>Maintaining your website is essential to ensuring it remains secure, up-to-date and doesn’t go down. Maintenance of a website can include:</p>
                <BulletList>
                  <Bullet key="1" text="Ensuring any plugins or apps are up-to-date" />
                  <Bullet key="2" text="That the code used to develop the website is still supported by your hosting provider" />
                  <Bullet key="3" text="Monitoring the website to ensure it remains functional" />
                  <Bullet key="4" text="Updating the content management system’s core code base" />
                  <Bullet key="5" text="Updating your SSL certificate to keep the website secure" />
                </BulletList>
              </div>              
            </div>
          </div>
        </div>

        <Spacer space="4" />

        <div className="row shrink-width align-center">

          <div className="column small-12 large-4">
            <h2 className="gm-bottom--1" style={{ lineHeight: 1 }}>Backups</h2>  
          </div>
          <div className="column small-12 large-8">
            <div className="row align-center">
              <div className="column small-12 large-10">
                <p>Another important part of website maintenance is ensuring that, should the website get hacked or something should accidentally get deleted or overwritten, you have a backup plan. As part of our maintenance packages, we do daily website backups and should anything happen to your website, we can restore it and get you back online as soon as possible.</p>
                <p>We are happy to provide website maintenance on a monthly or ad-hoc basis to make sure your website remains online, up-to-date and operating as it should.</p>
                <p>Please get in touch to find out how we could help to maintain your website and keep your business’ online presence from suffering due to lack of maintenance.</p>
                <Spacer space="3" />    
              </div>              
            </div>
          </div>
        </div>

        <div className="gm-top--2 gm-bottom--2 contact-form--pullout">
          <div className="clearfix"></div>
          <div className="row align-center gm-top--4 gm-bottom--4">
            <div className="column small-12 medium-10 large-8">
              <h3>Get in touch</h3>
              <p>We're driven by bringing great ideas to life and working with great people. Get in touch to find out more.</p>
              <Spacer space="2" />
              <ContactForm pageName="website-maintenance" />
            </div>
          </div>
          <div className="clearfix"></div>
        </div>

        <WorkGrid>
          <ImageGridItemService width={6} image={ '/maintenance.jpg' } subtitle="Development" title="WordPress Maintenance" link="/services/wordpress-maintenance" />
          <ImageGridItemService width={6} image={ '/security.jpg' } subtitle="Development" title="WordPress Security" link="/services/wordpress-security" />
        </WorkGrid>
 
        
        <Spacer space="4" />   
        <CenteredSlider>
          {data.allWordpressPost.edges.map(({ node }) => (
              <CenteredSlide key={node.id} title={node.title} image={ node.featured_media.localFile.childImageSharp.original.src } link={node.path} />
          ))}
        </CenteredSlider> 
      </Layout>
    )
  }
}

export default WebMaintenancePage

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          id
          slug
          path
          title
          featured_media {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }        
        }
      }
    }
  }
`